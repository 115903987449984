<template>
  <div id="trading">
    <div class="list">
      <trading-item></trading-item>
      <trading-item></trading-item>
      <trading-item :wave="-20.79"></trading-item>
      <trading-item></trading-item>
    </div>

  </div>
</template>

<script>
import TradingItem from './components/TradingItem.vue'
export default {
  name:'Trading',
  components: {
    TradingItem

  },
  data() {
    return {
    }
  },
  methods:{},
}
</script>

<style scoped>
#trading{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #2d2d2d;
}
</style>
