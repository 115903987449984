<template>
  <div class="trading-item" :class="{isDown:isDown}">
    <div class="item">
      <div class="logoBox"><div class="logo"><slot name=logo><svg-icon name='apple' color='#fff'/></slot></div></div> <!-- 产品的图标 -->
      <div class="tradingName">
        <h3><slot name=name>Apple Inc.</slot></h3> <!-- 产品的名称 -->
        <p><slot name=code>NASDAQ: AAPL</slot></p> <!-- 交易代码 -->
      </div>
      <div class="tradingWave" :class="{iscountDown:isDown}">
        <h4>{{negative}}{{wave}}$</h4> <!-- 价格涨跌 -->
        <h5>{{negative}}{{percent}}%</h5> <!-- 价格涨跌百分比 -->
      </div>
      <div class="tradingPrice" :class="{iscountDown:isDown}">
        <h2>${{price}}</h2> <!-- 产品的价格 -->
      </div>
    </div>


    <div class="chart-content">
      <div ref="chart" class="chart"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue';
export default {
  name:'TradingItem',
  components: {
    SvgIcon
  },
  data() {
    return {
      isDown: false,
      negative: '',
      percent:''
    }
  },
  props:{
    type:{
      type: String,
      default: "stock"
    },
    wave:{
      type: Number,
      default: 3.11
    },
    price:{
      type: Number,
      default: 147.11
    }
  },
  created(){
    
  },
  methods:{
    showChart(){
      this.chart = echarts.init(this.$refs.chart)
      var option = {
        xAxis: {
          show: false,
          data: ['', '', '', '', '', '', '', '', '', '', '', ''],
        },
        yAxis: {
          show: false,
        },
        axisPointer:{
          show: false,
        },
        series: [{
          name: '销量',
          type: 'line',
          symbol: "none",
          smooth: true,
          symbolSize: 5,
          sampling: 'average',
          data: [95, 120, 136, 150, 110, 147,157,174,190,160,147,147],
          lineStyle:{
            color:'#393E3E',
            opacity: .8
          },
          areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [{
                    offset: 0, 
                    color: 'rgba(178, 208, 206, 0.1)' // 0% 处的颜色
                }, {
                    offset: 1, 
                    color: 'rgba(178, 208, 206, 0)' // 100% 处的颜色
                }],
                global: false // 缺省为 false
              }
          },
        }]
      } 
      this.chart.setOption(option)
    }
  },
  mounted(){
    this.showChart()
    if(this.wave>0){
      this.isDown = false
      this.negative="+"
    } else if(this.wave<0){
      this.isDown = true
      this.negative=""
    } else {
      this.isDown = true
    }
    this.percent=Math.round(this.wave / this.price * 10000) / 100
    
  }
}
</script>

<style scoped>
.trading-item{
  position: relative;
  width: 480px;
  height: 80px;
  background: #751425;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 26px;
  cursor: pointer;
  transition: .6s;
  margin-bottom: 20px;
  color: aliceblue;
  overflow: hidden;
}
.trading-item:hover{
  transform: translateX(-10px);
}
.item{
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  z-index: 2;
}
.chart-content{
  width: 100%;
  height: 80px;
}
.isDown{
  background: #004934;
}
.logoBox{
  width: 60px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo{
  width: 32px;
  height: 32px;
  z-index: 2;
}
.tradingName{
  height: 60px;
  width: 50%;
  z-index: 3;
}
.tradingWave{
  height: 50px;
  width: 60px;
  color: #FF1515;
  z-index: 3;
  text-align: end;
}
p{
  font-size: 10px;
}
.tradingPrice{
  height: 36px;
  width: 22%;
  text-align: end;
  color: #FF1515;
  z-index: 3;
}
.iscountDown{
  color: #1BFF5B;
}
.chart{
  width: 136%;
  height: 80px;
  margin-left: -90px;
  margin-top: -60px;
}
h4{
  font-size: 18px;
}
h5{
  font-size: 12px;
}
</style>
